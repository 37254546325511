export const ERRORS_TITLE = "ERRORS_ALERTS_TITLE";
export const ERRORS_CHECKOUT = "ERRORS_CHECKOUT";
export const ERRORS_NO_SCRIPT_ID = "ERRORS_NO_SCRIPT_ID";
export const ERRORS_NO_APP_ID = "ERRORS_NO_APP_ID";
export const ERRORS_INVALID_APP_ID = "ERRORS_INVALID_APP_ID";
export const ERRORS_NO_CHECKOUT = "ERRORS_NO_CHECKOUT";
export const ERRORS_NOT_PAYMENT_CHECKOUT = "ERRORS_NOT_PAYMENT_CHECKOUT";
export const ERRORS_SUBSCRIPTION_NOT_ALLOWED_FOR_ONLINE = "ERRORS_SUBSCRIPTION_NOT_ALLOWED_FOR_ONLINE";
export const ERRORS_INVALID_TOKEN_TYPE = "ERRORS_INVALID_TOKEN_TYPE";
export const ERRORS_INVALID_AMOUNT = "ERRORS_INVALID_AMOUNT";
export const ERRORS_INVALID_DATE = "ERRORS_INVALID_DATE";
export const ERRORS_INVALID_CURRENCY = "ERRORS_INVALID_CURRENCY";
export const ERRORS_INVALID_CAPTURE_IN = "ERRORS_INVALID_CAPTURE_IN";
export const ERRORS_INVALID_SUBSCRIPTION_START_IN = "ERRORS_INVALID_SUBSCRIPTION_START_IN";
export const ERRORS_INVALID_SUBSCRIPTION_RETRY_INTERVAL = "ERRORS_INVALID_SUBSCRIPTION_RETRY_INTERVAL";
export const ERRORS_INVALID_SUBSCRIPTION_PERIOD = "ERRORS_INVALID_SUBSCRIPTION_PERIOD";
export const ERRORS_INVALID_LOCALE = "ERRORS_INVALID_LOCALE";
export const ERRORS_INVALID_USAGE_LIMIT = "ERRORS_INVALID_USAGE_LIMIT";
export const ERRORS_UNNECESSARY_AMOUNT = "ERRORS_UNNECESSARY_AMOUNT";
export const ERRORS_UNNECESSARY_CURRENCY = "ERRORS_UNNECESSARY_CURRENCY";
export const ERRORS_UNNECESSARY_SUBSCRIPTION_ID = "ERRORS_UNNECESSARY_SUBSCRIPTION_ID";
export const ERRORS_UNNECESSARY_INSTALLMENT_PLAN = "ERRORS_UNNECESSARY_INSTALLMENT_PLAN";
export const ERRORS_UNNECESSARY_INSTALLMENT_PLAN_QTY = "ERRORS_UNNECESSARY_INSTALLMENT_PLAN_QTY";
export const ERRORS_NO_SUBSCRIPTION_PERIOD = "ERRORS_NO_SUBSCRIPTION_PERIOD";
export const ERRORS_TITLE_TOO_LONG = "ERRORS_TITLE_TOO_LONG";
export const ERRORS_INVALID_INSTALLMENT_PLAN = "ERRORS_INVALID_INSTALLMENT_PLAN";
export const ERRORS_INVALID_SUBSCRIPTION_PLAN = "ERRORS_INVALID_SUBSCRIPTION_PLAN";
export const ERRORS_INVALID_INSTALLMENT_AMOUNT = "ERRORS_INVALID_INSTALLMENT_AMOUNT";
export const ERRORS_INVALID_INSTALLMENT_INITIAL_AMOUNT = "ERRORS_INVALID_INSTALLMENT_INITIAL_AMOUNT";
export const ERRORS_INVALID_CUSTOMER_ID = "ERRORS_INVALID_CUSTOMER_ID";
export const ERRORS_INVALID_TIMEZONE = "ERRORS_INVALID_TIMEZONE";
export const ERRORS_INVALID_PAYMENT_TYPE = "ERRORS_INVALID_PAYMENT_TYPE";
export const ERRORS_INVALID_PAYMENT_TYPES = "ERRORS_INVALID_PAYMENT_TYPES";
export const ERRORS_INVALID_INSTALLMENTS = "ERRORS_INVALID_INSTALLMENTS";
export const ERRORS_INVALID_PRODUCTS = "ERRORS_INVALID_PRODUCTS";
export const ERRORS_INVALID_AUTH_CODE = "ERRORS_INVALID_AUTH_CODE";
export const ERRORS_INVALID_COLOR_CODE = "ERRORS_INVALID_COLOR_CODE";
export const ERRORS_INVALID_LOGO_TYPE = "ERRORS_INVALID_LOGO_TYPE";
export const ERRORS_INVALID_SHIPPING_ADDRESS = "ERRORS_INVALID_SHIPPING_ADDRESS";
export const ERRORS_INVALID_SHIPPING_ADDRESS_COUNTRY_CODE = "ERRORS_INVALID_SHIPPING_ADDRESS_COUNTRY_CODE";
export const ERRORS_INVALID_SHIPPING_ADDRESS_ZIP = "ERRORS_INVALID_SHIPPING_ADDRESS_ZIP";
export const ERRORS_EXPIRATION_TIMESHIFT = "ERRORS_EXPIRATION_TIMESHIFT";
export const ERRORS_EXPIRATION_PERIOD = "ERRORS_EXPIRATION_PERIOD";
export const ERRORS_PAIDY_ZIP_CODE_REQUIRED = "ERRORS_PAIDY_ZIP_CODE_REQUIRED";
export const ERRORS_INVALID_METADATA = "ERRORS_INVALID_METADATA";
export const ERRORS_GENERIC = "ERRORS_GENERIC";
export const ERRORS_INVALID_ALLOW_INSTALLMENTS_CHECKOUT = "ERRORS_INVALID_ALLOW_INSTALLMENTS_CHECKOUT";
export const ERRORS_INVALID_ALLOW_INSTALLMENTS_TOKEN_TYPE = "ERRORS_INVALID_ALLOW_INSTALLMENTS_TOKEN_TYPE";
export const ERRORS_INVALID_ALLOW_INSTALLMENTS_USAGE_LIMIT = "ERRORS_INVALID_ALLOW_INSTALLMENTS_USAGE_LIMIT";
export const ERRORS_NO_CUSTOM_FIELD_KEY = "ERRORS_NO_CUSTOM_FIELD_KEY";
export const ERRORS_NO_CUSTOM_FIELD_LABEL = "ERRORS_NO_CUSTOM_FIELD_LABEL";
export const ERRORS_NO_CUSTOM_FIELD_TYPE = "ERRORS_NO_CUSTOM_FIELD_TYPE";
export const ERRORS_NO_CUSTOM_FIELD_OPTIONS = "ERRORS_NO_CUSTOM_FIELD_OPTIONS";
export const ERRORS_INVALID_THREE_DS_MODE = "ERRORS_INVALID_THREE_DS_MODE";
export const PAYMENT_TYPE_NOT_SUPPORTED = "PAYMENT_TYPE_NOT_SUPPORTED";
export const THREE_DS_COULD_NOT_BE_COMPLETED = "THREE_DS_COULD_NOT_BE_COMPLETED";
export const UNFINISHED_CHARGE_EXIST_FOR_TOKEN = "UNFINISHED_CHARGE_EXIST_FOR_TOKEN";
export const PAYMENT_EXPIRATION_EXCEEDS_PERIOD = "PAYMENT_EXPIRATION_EXCEEDS_PERIOD";
